import React from "react";
import "twin.macro";

const Human = ({ human }) => {
  return (
    <div tw="p-6 md:p-12">
      <h1 tw="font-bold text-4xl pb-8">
        {human.name} {human.surname}
      </h1>
      <p tw="pb-6">{human.desc1}</p>
      <p tw="pb-6">{human.desc2}</p>
      <p tw="pb-6">{human.desc3}</p>
      {human.desc4 && <p tw="pb-6">{human.desc4}</p>}
      {human.desc5 && <p tw="pb-6">{human.desc5}</p>}
      {human.desc5a && <p tw="pb-6">{human.desc5a.top}</p>}
      {human.desc6 && <p tw="pb-6">{human.desc6}</p>}
      {human.desc7 && <p tw="pb-6">{human.desc7}</p>}
      <div>
          {human.phone !== null && <><span>+48 </span>
        <span>{human.phone[0]}-</span>
        <span>{human.phone[1]}-</span>
        <span>{human.phone[2]}</span></>}
      </div>
    </div>
  );
};

export default Human;
