import React, { useEffect } from "react";
import { PageSection } from "../../styles/index/_components";
import Layout, { PageWrapper } from "../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import Human from "../../components/people/Human";
import "twin.macro";
import { cleanUpZnanyLekarz, loadZnanyLekarz } from "../../utils/utils";

const JuliannaText = {
  name: "Julianna",
  surname: "Graczyk",
  phone: ["508", "247", "687"],
  desc1:
    "Jestem absolwentką Uniwersytetu SWPS na kierunku Psychoseksuologia oraz Psychologia Kliniczna. Aktywnie działam w Stowarzyszeniu Artystyczno-Społecznym Feeria, w którym obejmuje rolę psycholożki, piszę projekty i udzielam się artystycznie.\n" +
    "Pracę opartą o kontakt z ludźmi zaczęłam bardzo wcześnie, najpierw przez sztukę, teatr, performance, studia na Akademii Sztuk Pięknych. Następnie postanowiłam moją ścieżkę zawodową skierować właśnie na ten obszar: człowieka. Wiem, że przy połączeniu wiedzy naukowej i empatii jesteśmy w stanie pomóc. W bezpiecznej przestrzeni dialogu jesteśmy zdolni do analizowania emocji, przeżyć, generowania zmian i motywacji do nich jak i akceptacji, spokoju i uczucia ulgi.",
  desc2:
    "Mam doświadczenie w pracy z osobami z grup zagrożonych wykluczeniem społecznym przy projektach takich jak Teatr Forum w Gdańskim Areszcie Śledczym, konsultacje psychologiczne i seksuologiczne dla Gdańskiego Centrum Równego Traktowania, przygotowywanie kampanii i działań społecznych na rzecz osób LGBTQIA+.\n" +
    "Pracowałam jako asystentka osób z niepełnosprawnością na Uniwersytecie SWPS. Ukończyłam kurs “Formy i metody pracy z osobami z niepełnosprawnością psychiczną” Pomorskiej Koalicji na rzecz zdrowia psychicznego",
  desc3:
    "Byłam ekspertką Gdańskiego Modelu na Rzecz Równego Traktowania w zakresie Zdrowie gdzie prowadziałam badania pod opieką Dr Anny Strzałkowskiej.\n" +
    "Jestem certyfikowaną trenerką antydyskryminacyjną (Kurs treningu antydyskryminacyjnego i równościowego, Centrum psychoterapii i rozwoju Ku Sobie).\n" +
    "Pracuję w duchu Dialogu Motywującego (DM w pracy praktycznej i terapeutycznej, Polskie Towarzystwo Dialogu Motywującego).\n" +
    "Praktyki zawodowe odbywałam w Szpitalu dla Nerwowo i Psychicznie Chorych im. Stanisława Kryzana w Starogardzie Gdańskim oraz Poradni Seksuologicznej i Psychoterapii INTIMED – Zakład Seksuologii Klinicznej w Gdyni",
  desc4:
    "W pracy akademickiej interesują mnie tematy z zakresu fantazji seksualnych, komunikacji potrzeb i praktyk seksualnych.",
};

const Julianna = () => {
  useEffect(() => {
    cleanUpZnanyLekarz(document, "zl-widget-s");
    loadZnanyLekarz(document, "script", "zl-widget-s");
  });

  return (
    <Layout>
      <PageSection>
        <PageWrapper>
          <div tw="grid grid-flow-row md:grid-flow-col md:grid-cols-2 items-start">
            <StaticImage
              alt={"human picture"}
              src={"../../images/people/julianna.jpg"}
              placeholder="blurred"
              layout="fullWidth"
            />
            <Human human={JuliannaText} />
          </div>
          <div>
            <a
              id="zl-url"
              className="zl-url"
              href="https://www.znanylekarz.pl/julianna-graczyk/psycholog/gdansk"
              rel="nofollow"
              data-zlw-doctor="julianna-graczyk"
              data-zlw-type="big_with_calendar"
              data-zlw-opinion="false"
              data-zlw-hide-branding="true"
            >
              Julianna Graczyk - ZnanyLekarz.pl
            </a>
          </div>
        </PageWrapper>
      </PageSection>
    </Layout>
  );
};

export default Julianna;
